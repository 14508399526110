<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal">
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-5 pt-12 w-full">
      <div class="items-center w-1/2  header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-5 mt-5">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
          class="sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1"
        >
          <AppInput
            type="text"
            rules="required"
            name="Name"
           :label="$t('components.pods.sunpods.addEdit.title.name')"
            v-model="form.name"
          />
          <AppInput
            type="number"
            rules="required"
            :label="$t('components.pods.sunpods.addEdit.title.instance')"
            placeholder="e.g. 127"
            v-model="form.instance"
          />

          <div>
            <AppInput
              type="richselect"
              rules="required"
              :label="$t('components.pods.sunpods.addEdit.title.assignedEdgebox')"
              :placeholder="getAssignedEdgeboxPlaceholder"
              :disabled="getAssignedEdgeboxDisabledState"
              value-attribute="id"
              text-attribute="name"
              v-model="form.assigned_edgebox"
              :options="getEdgeboxes"
              hide-search-box
            />
            <p
              class="text-sm text-gray-500"
              v-if="!isEdgeboxLoading && getEdgeboxes.length === 0"
            >
              <RouterLink
                :to="{
                  name: 'EdgeboxIndex',
                }"
                target="_blank"
                class="text-blue-600 capitalize font-semibold"
              >
                Create Edge Box
              </RouterLink>
              at first
            </p>
          </div>
          <AppInput
            type="text"
            rules="required"
             :name="$t('components.pods.sunpods.addEdit.title.vRMUsername')"
           :label="$t('components.pods.sunpods.addEdit.title.vRMUsername')"
            v-model="form.vrm_user_email"
          />
          <AppInput
            type="password"
            rules="required"
            :name="$t('components.pods.sunpods.addEdit.title.vRMPassword')"
           :label="$t('components.pods.sunpods.addEdit.title.vRMPassword')"
            v-model="form.vrm_password"
          />
          <AppInput
            type="text"
            rules="required"
            :name="$t('components.pods.sunpods.addEdit.title.cameraIP')"
           :label="$t('components.pods.sunpods.addEdit.title.cameraIP')"
            v-model="form.sunpod_camera_ip"
          />
          <AppInput
            type="text"
            rules="required"
            :name="$t('components.pods.sunpods.addEdit.title.cameraRTSPUsername')"
           :label="$t('components.pods.sunpods.addEdit.title.cameraRTSPUsername')"
            v-model="form.sunpod_camera_rtsp_user"
          />
          <AppInput
            type="text"
            rules="required"
            :name="$t('components.pods.sunpods.addEdit.title.cameraRTSPPassword')"
           :label="$t('components.pods.sunpods.addEdit.title.cameraRTSPPassword')"
            v-model="form.sunpod_camera_rtsp_password"
          />
          <div>
            <AppInput
              type="richselect"
              rules="required"
                :label="$t('components.pods.sunpods.addEdit.title.sunpodStationArea')"
              :placeholder="getSunpodStationPlaceholder"
              :disabled="getAssignedSunpodStationDisabledState"
              value-attribute="id"
              text-attribute="name"
              v-model="form.sun_pod_area"
              :options="getSunpodStations"
            />
            <p
              class="text-sm text-gray-500"
              v-if="!isSunpodStationLoading && getSunpodStations.length === 0"
            >
              <RouterLink
                :to="{
                  name: 'SunpodStationIndex',
                }"
                target="_blank"
                class="text-blue-600 capitalize font-semibold"
              >
                Create Sunpod Station
              </RouterLink>
              at first
            </p>
          </div>
          <AppInput
            type="richselect"
            rules="required"
               :label="$t('components.pods.sunpods.addEdit.title.status')"
            v-model="form.is_active"
            :options="[
              { text: 'Active', value: true },
              { text: 'Inactive', value: false },
            ]"
            hide-search-box
          />

          <button type="submit" ref="submitButton" class="hidden">
          {{
                $t('components.stepNavigation.save')
              }}
          
          </button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">{{
            $t('components.stepNavigation.cancel')
          }}</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3">{{
          actionButtonText
        }}</t-button>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import { SunpodsConfig } from '@/config/SunpodsConfig'
import { EdgeboxConfig } from '@/config/EdgeboxConfig'
import { EventBus } from '@/utils/EventBus'
import SlideOverRight from '@/components/modals/SlideOverRight'
import AnchorButton from '@/components/form/AnchorButton'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import xMan from '@/utils/xMan'
export default {
  name: 'AddEditSunpod',
  components: {
    SlideOverRight,
    AnchorButton,
    SlideOverRightTrigger,
  },
  data() {
    return {
      sorId: SunpodsConfig.events.sorId,
      isLoading: false,
      isEdgeboxLoading: false,
      isSunpodStationLoading: false,
      form: {
        name: null,
        instance: null,
        assigned_edgebox: null,
        vrm_user_email: null,
        vrm_password: null,
        is_active: true,
        sun_pod_area: null,
        sunpod_camera_ip: null,
        sunpod_camera_rtsp_user: null,
        sunpod_camera_rtsp_password: null,
      },
      item: {},
      edgeboxes: [],
      sunpodStations: [],
    }
  },
  async created() {
    this.isEdgeboxLoading = true

    await this.$http
      .get(EdgeboxConfig.api.index)
      .then((res) => (this.edgeboxes = res.data.data))
      .finally(() => (this.isEdgeboxLoading = false))

    this.isSunpodStationLoading = true
    await this.$http
      .get(`${this.$config.sunpodStationArea.api.index}?export=true`)
      .then((res) => {
        this.sunpodStations = res.data.data
      })
      .finally(() => (this.isSunpodStationLoading = false))
  },

  mounted() {
    EventBus.$on(SunpodsConfig.events.editingData, (item) => {
      console.log('sunpod-ad', item)
      this.item = item
      this.form.name = this.item.name
      this.form.instance = this.item.instance
      this.form.assigned_edgebox = this.item.assigned_edgebox
      this.form.vrm_user_email = this.item.vrm_user_email
      this.form.vrm_password = this.item.vrm_password
      this.form.sunpod_camera_ip = this.item.sunpod_camera_ip
      this.form.sunpod_camera_rtsp_user = this.item.sunpod_camera_rtsp_user
      this.form.sunpod_camera_rtsp_password = this.item.sunpod_camera_rtsp_password
      this.form.is_active = this.item.is_active
      this.form.sun_pod_area = this.item.sun_pod_area
    })
  },
  computed: {
    titleText() {
      return this.isEditing ? 
          this.$t('components.pods.sunpods.addEdit.headline.edit')
        : this.$t('components.pods.sunpods.addEdit.headline.add')
    },
    isEditing: function() {
      return Object.keys(this.item).length > 1
    },
    actionButtonText: function() {
      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.save')
    },
    getEdgeboxes() {
      return this.edgeboxes
    },
    getAssignedEdgeboxPlaceholder() {
      if (this.isEdgeboxLoading) return this.$t(
          'components.pods.sunpods.addEdit.placeHolder.assignedEdgebox.loading'
        )
      if (this.isEdgeboxLoading === false && this.getEdgeboxes.length === 0)
        return this.$t(
          'components.pods.sunpods.addEdit.placeHolder.assignedEdgebox.noEdgeboxFound'
        )
      return  this.$t(
          'components.pods.sunpods.addEdit.placeHolder.assignedEdgebox.selectAEdgebox'
        )
    },
    getAssignedEdgeboxDisabledState() {
      if (this.isEdgeboxLoading) return true
      if (this.isEdgeboxLoading === false && this.getEdgeboxes.length === 0)
        return true
      return false
    },
    getSunpodStations() {
      return this.sunpodStations
    },
    getSunpodStationPlaceholder() {
      if (this.isSunpodStationLoading) return this.$t(
          'components.pods.sunpods.addEdit.placeHolder.sunpodStationArea.loading'
        )
      if (
        this.isSunpodStationLoading === false &&
        this.getSunpodStations.length === 0
      )
        return this.$t(
          'components.pods.sunpods.addEdit.placeHolder.sunpodStationArea.noEdgeboxFound'
        )
      return this.$t(
          'components.pods.sunpods.addEdit.placeHolder.sunpodStationArea.selectAEdgebox'
        )
    },
    getAssignedSunpodStationDisabledState() {
      if (this.isSunpodStationLoading) return true
      if (
        this.isSunpodStationLoading === false &&
        this.getSunpodStations.length === 0
      )
        return true
      return false
    },
  },

  methods: {
    init() {
      this.form = {
        name: null,
        instance: null,
        assigned_edgebox: null,
        vrm_user_email: null,
        vrm_password: null,
        is_active: true,
        sun_pod_area: null,
        sunpod_camera_ip: null,
        sunpod_camera_rtsp_user: null,
        sunpod_camera_rtsp_password: null,
      }
    },
    submit() {
      this.$refs.submitButton.click()
    },
    showModal() {
      dispatchEvent(new Event(SunpodsConfig.events.sorToggle))
    },

    async confirmSubmit() {
      const formProxy = { ...this.form }
      const propertyNames = Object.keys(formProxy)
      propertyNames.map((name) => {
        if (formProxy[name] === null) {
          delete formProxy[name]
        }
      })

      console.log('form = ', formProxy)

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? SunpodsConfig.api.update(this.item.id)
        : SunpodsConfig.api.create
      let data = new xMan(formProxy).toFormData()

      let message = this.isEditing
        ? 'Sunpod updated successfully'
        : 'Sunpod added successfully'

      this.isLoading = true

      await this.$http({
        method,
        url,
        data,
      })
        .then((res) => {
          console.log('sunpod', res.data.id)
          if (res.data.id && this.form.assigned_edgebox) {
            const url = SunpodsConfig.api.assignedEdgebox(res.data.id)
            const data = new FormData()
            data.append('edgebox_id', this.form.assigned_edgebox)
            this.$http
              .post(url, data)
              .then((res) => {
                console.log('res-edgebox', res)
                // Close the slideover
                dispatchEvent(new Event(SunpodsConfig.events.sorToggle))
                dispatchEvent(new Event(SunpodsConfig.events.refresh))
                this.$emit('add-edit')
                // Reset the form
                this.form = {}
                this.$notify({
                  group: 'bottomLeft',
                  type: 'success',
                  title: 'Success',
                  text: message,
                })
              })
              .finally(() => (this.isLoading = false))
          } else {
            // Close the slideover
            dispatchEvent(new Event(SunpodsConfig.events.sorToggle))
            dispatchEvent(new Event(SunpodsConfig.events.refresh))
            this.$emit('add-edit')
            // Reset the form
            this.form = {}
            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            })
          }
        })
        .catch((error) => {
          const e = error.response
          console.log('err', e)

          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [Code: ${e.status}]`,
            text: e.data ?? 'The action could not be executed',
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
</style>
